.frais-detail {
    border: 1px solid #f1f1f1;
    margin-bottom: 2rem;
    padding-bottom: 0;
    overflow: hidden;

    .frais-title {
        font-weight: bold;
        font-size: 16px;
        padding: 0rem 1rem;
        padding-top: .5rem;
        
        span {
            text-decoration: underline;
        }
    }

    .frais-loading {
        border-bottom: 1px solid #f1f1f1;
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
    }

    .frais-item {
        border-bottom: 1px solid #f1f1f1;
        padding: 1rem 2rem;
        display: flex;

        .frais-name {
            width: 40%;
            font-size: 14px;
        }

        .frais-amount {
            flex: auto;
            font-size: 14px;
            text-align: right;
        }
    }

    .frais-total {
        padding: 1rem 2rem;
        background-color: #f7f7f7;
        font-size: 16px;
        font-weight: bolder;

        display: flex;
        justify-content: center;

        .frais-name {
            width: 40%;
            white-space: nowrap;
        }

        .frais-amount {
            flex: auto;
            text-align: right;
        }
    }
}