
.detailed-sales {
    display: flex;
    
    .sales-wrapper {
        display: flex;
    }

    .sales-year {
        .sales-subtitle {
            flex: auto;
            font-size: 18px;
            text-decoration: underline;
        }

        display: flex;
        align-items: center;
        justify-content: flex-start;

        .select-block {
            margin-right: 1rem;
        }
    }

    .sales-table {
        background-color: #caf0f8;
        border-radius: .5rem;
        width: 50%;
        padding: 1rem 1.5rem;
        font-size: 16px;
        .sales-block {
            display: flex;
            align-items: center;
            justify-self: center;
            margin-right: 10rem;
    
            .sales-title {
                margin-right: 1rem;
            }
    
            .sales-description {
                padding-top: 10px;
            }
        }

        .table-wrapper {
            display: flex;
        
            .table-block {
                padding-top: 2rem;
                width: 100%;
        
                .first-column {
                    border-bottom: 1px solid black;
                }
    
                .table-summary {
                    width: 100%;
                    // tbody, thead {
                    //     tr {
                    //         td, th {
                    //             padding: .5rem 1rem;
    
                    //             &.currency {
                    //                 text-align: right;
                    //             }
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
    
    .chart-wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem 1.5rem;
    }
    
}


