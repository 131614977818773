.frais-selector {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1rem;

    .frais-loading {
        padding: 2rem 2rem;
        border: 3px dotted rgb(228, 227, 227);
        margin-right: 1rem;
        width: 180px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .loading-icon {
            height: 75px;
            padding: .5rem;
            transition: all ease-out 250ms;
            font-size: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .frais-box {
        padding: 2rem 2rem;
        border: 3px dotted rgb(228, 227, 227);
        margin-right: 1rem;
        width: 180px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .frais-check-active {
            opacity: 0;
            position: absolute;
            top: .5rem;
            right: .5rem;
            background-color: chartreuse;
            border-radius: 9999px;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            transition: all ease-out 400ms;
        }

        &:hover, &.active {
            border: 3px dotted greenyellow;
            cursor: pointer;

            transition: all ease-in 250ms;
        }

        &.active {
            .frais-check-active {
                display: flex;
                opacity: 1;
                transition: all ease-in 400ms;
            }
        }

        img {
            height: 75px;
            padding: .5rem;
            transition: all ease-out 250ms;
        }
    }
}