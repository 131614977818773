.dashboard-page {
    .dashboard-title {
        .main-title {
            font-weight: bold;
        }
    }

    .dashboard-result {
        .previous-result {

            .previous-title {
                .title-text {
                    padding-left: 3rem;
                }
            }
            .previous-block {
                display: flex;
                align-items: center;
                justify-content: center;

                .previous-subtitle {
                    flex:auto;
                    padding-left: 1rem;
                }

                .previous-search {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .search-date {
                        .search-text {
                            margin-right: 1rem;
                        }
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 2rem;
                    }
                }
            }
        }
    }  
}