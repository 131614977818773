.agence-form {
    .agence-info {
        width: 40%;
    }

    .agence-adresse {
        width: 55%;
    }
}

@media screen and (max-width: 480px) {
    .agence-form {
        .agence-info {
            width: 100%;
        }

        .agence-adresse {
            width: 100%;
        }
    }
}