.commission-detail {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);

    .commission-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .25);
        opacity: 0;
    }

    .commission-body {
        background-color: white;
        padding: .475rem;
        border-radius: .475rem;
        position: relative;
        z-index: 200;
        transform: scale(1, 0);

        .commission-header {
            display: flex;
            .commission-bien img {
                height: 225px;
            }
    
            .commission-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        
    }

    &.show {
        transform: scale(1);

        .commission-body {
            transition: all ease-in 250ms;
            transform: scale(1);
        }

        .commission-overlay {
            transform-origin: top;
            transition: all ease-in 400ms;
            opacity: 1;
        }
    }

    &.hide {
        .commission-body {
            transition: all ease-out 250ms;
            transform: scale(1, 0);
        }

        .commission-overlay {
            transform-origin: top;
            transition: all ease-out 400ms;
            opacity: 0;
        }
    }
}


@media screen and (max-width: 480px) {
    .commission-detail {
        margin-top: 2.5rem;
        z-index: 1060;
        .commission-body {
            background-color: white;
            padding: .475rem;
            border-radius: .475rem;
            position: relative;
            z-index: 200;
            transform: scale(1, 0);
    
            .commission-header {
                display: flex;
                flex-direction: column;

                .commission-info {
                    padding-top: 1rem;
                }
            }
        }
    }
}