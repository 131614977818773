.sales-turnover {
    .nav-tabs {
        margin-bottom: 1rem;

        .nav-link {
            font-size: 18px;
            margin-right: 1rem;
            cursor: pointer;
            color: black;

            &:first-child {
                margin-left: 1rem;
            }

            &:hover, &:focus {
                color: #35a8ff;
                border-color: #35a8ff;

                &.active {
                    border: 1px solid;

                    &.type-1 {
                        color: #008ffb !important;
                        border-color: #008ffb !important;
                    }

                    &.type-2 {
                        color: #f48c06 !important;
                        border-color: #f48c06 !important;
                    }
                    
                }
            }
        }

        &.type-1 {
            border-color: #0096c7;

            .nav-link {
                &.active {
                    border: 1px solid;
                    color: #0096c7 !important;
                    border-color: #0096c7 !important;
                }
            }
        }

        &.type-2 {
            border-color: #f48c06;

            .nav-link {
                &.active {
                    border: 1px solid;
                    color: #f48c06 !important;
                    border-color: #f48c06 !important;
                }
            }
        }
    }
}