.negociateurs-sales {
    display: flex;            

    .negociateurs-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-self: center;
        // margin-right: 10rem;

        .negociateurs-title {
            margin-right: 1rem;
        }

        .negociateurs-description {
            padding-top: 10px;
            flex: auto;
            font-size: 18px;
            text-decoration: underline;
        }
    }

    .sales-year {
        display: flex;
        align-items: center;
        justify-self: center;

        .select-block {
            margin-right: 1rem;
        }
    }
}

.negociateurs-wrapper {
    width: 100%;
    // display: flex;
    align-items: flex-start;

    .negociateurs-table {
        width: 30%;
        background-color: #caf0f8;
        border-radius: .5rem;
        padding: 1rem 1.5rem;
        font-size: 16px;
        margin-top: 2rem;

        table {
            width: 100%;

            thead tr th, tbody tr td {
                &.currency {
                    text-align: right;
                    // padding: .5rem 1rem;
                }
            }
        }

        

        .first-column {
            border-bottom: 1px solid black;
        }
    }

    .negociateurs-chart {
        // width: 100%;
        padding: 2rem 2.5rem;
    }
}

