.bien-list {
    margin-top: 0;
    .bien-picture {
        width: 125px;

        img {
            width: 100%;
        }
    }

    .bien-info {
        width: 175px;

        * {
            white-space: pre-wrap;
        }
    }

    .bien-datatable {
        tr th {
            vertical-align: middle;
        }

        .badge-provenance {
            border: 1px solid orangered;
            display: inline-block;
            padding: 0.5em 0.85em;
            font-size: 0.95rem;
            font-weight: 600;
            line-height: 1;
            color: orangered;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.475rem;
            margin-bottom: .475rem;
        }

        .mandat-type {
            background-color: rgb(30, 30, 172);
            color: white;
            padding: 0.5em 0.85em;
            border-radius: 0.475rem;
            font-size: 0.85rem;
            font-weight: 600;
            margin-bottom: .475rem;
        }
    }
}

@media screen and (max-width: 480px) {
    .bien-list {
        margin-top: 6rem;
    }
}