.user-box {

    .card-body {
        border-left: 3px solid #34ace0;
        border-right: 2px solid grey;
        border-radius: 6px !important;
    }
    
    .user-information {
        display: flex;

        .user-profil {
            height: 125px;
            width: 125px;
            background-color: #ccc;

            background-size: cover;
            background-repeat: no-repeat;
            @media screen and (max-width:600px) {
                height: auto;
                width: auto;
            }
        }

        .user-detail {
            padding-left: 2rem;
            
            flex: auto;
            border-right: 1px dotted #ccc;
            .user-name {   
                font-size: 22px;
                font-weight: 600;
                color: rgb(85, 85, 85);
            }

            .user-contact {   
                font-size: 16px;
                display: flex;
                & > div {
                    padding-right: 1.5rem;
                }

                svg {
                    margin-right: .25rem;
                }
                @media screen and (max-width: 850px) {
                    display: block;
                }
            }

            @media screen and (max-width:600px) {
                padding-left: 0px;
                border-right: 0px;
            }
        }
        
        .user-action {
            padding-left: 3rem;
            @media screen and (max-width:600px) {
                padding-left: 0px;
            }
        }

        @media screen and (max-width:400px) {
            display: block;
        }
    }

    .user-stats {
        display: flex;
        font-size: 16px;
        margin-top: 1rem;

        .stat-wrapper {
            padding-right: 2.5rem;
            display: flex;

            @media screen and (max-width:600px) {
                display: block;
                .bloc-info{
                    display: flex;
                    justify-content: space-between;
                }
            }

            .stat-title {
                color: grey;

                &.ca-annuel {
                    color: rgb(0, 89, 255);
                }
            }

            .stat-value {
                color: rgb(30, 30, 30);
                font-weight: 600;

                
            }
            svg {
                margin-right: 1rem;
                font-size: 28px;
            }
        }

        @media screen and (max-width:600px) {
            display: block;
        }
    }
}