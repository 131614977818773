.datatable {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    .datatable-responsive {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;

        .filters {
            padding-bottom: .75rem;
        }

        // &.visible {
        //     transform: scale(1);
        //     overflow: hidden;
        // }
    
        // &.hidden {
        //     transform: scale(0);
        //     overflow: hidden;
        // }
    
        // table {
        //     min-width: 100%;
    
        //     thead tr th {
        //         background-color: #F8F8F9;
        //         color: rgba(0, 0, 0, 0.87);
        //         font-weight: 500;
        //         line-height: 1.5rem;
        //         padding: .75rem 2rem;
        //         color: gray;
        //         text-align: left;
    
        //         border-top: 1px solid rgba(224, 224, 224, 1);
        //         border-bottom: 1px solid rgba(224, 224, 224, 1);
    
        //         &:first-child {
        //             border-left: 1px solid rgba(224, 224, 224, 1);
        //         }
    
        //         &:last-child {
        //             border-right: 1px solid rgba(224, 224, 224, 1);
        //         }
        //     }
    
        //     tbody tr {
        //         &:last-child {
        //             border-bottom: 1px solid rgba(224, 224, 224, 1);
        //         }
    
        //         td {
        //             background-color: white;
        //             color: rgb(50, 50, 50);
        //             padding: .75rem 2rem;
        //             font-size: 0.875rem;
        //             text-align: left;
        //             font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        //             font-weight: 400;
        //             line-height: 1.43;
        
        //             &:first-child {
        //                 border-left: 1px solid rgba(224, 224, 224, 1);
        //             }
        
        //             &:last-child {
        //                 border-right: 1px solid rgba(224, 224, 224, 1);
        //             }
        //         }
        //     }
        // }
    }

    .table-footer {
        display: flex;
        align-items: center;

        .pagination-display {
            margin-top: 1rem;
            display: flex;
            flex: auto;
            font-size: 14px;
            color: rgb(50, 50, 50);
        }
    
        .pagination-links {
            ul {
                margin-top: 1rem;
                display: flex;
    
                li {
                    padding: .5rem .75rem;
                    background-color: white;
                    margin-left: .5rem;
                    box-shadow: -1px 0px 4px 0px rgba(50, 50, 50, .025);
    
                    &.selected {
                        // background-color: gray;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}