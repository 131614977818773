.dropzone {
    border-image: none;
    padding: 1.75rem 2rem;
    text-align: center;


    .notice {
        padding: 4rem !important;
    }

    &.small-dropzone {
        padding: 0rem .75rem;
    }

    .dropzone-action {
        white-space: nowrap;
        transition: opacity ease-out 400ms;

        &:hover {
            opacity: .55;
            transition: opacity ease-in 400ms;
        }
    }
}