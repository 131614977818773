*:disabled, button:disabled, .btn:disabled {
  opacity: .75;
  cursor: not-allowed !important;
}

button {
  white-space: nowrap !important;
}

.add-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  background: white;
  padding: 1.5rem 2.5rem !important;
  padding-bottom: 1rem !important;
}

.field-error {
  padding-top: .5rem;
  color: #d73038;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-auto {
  flex: auto;
}

.relative {
  position: relative;
}

.cover-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spin-animation {
  -webkit-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
  -moz-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;  
  -ms-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
  -o-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
  animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
}

.spin-quickly {
  -webkit-animation: spin .6s ease-in infinite;
  -moz-animation: spin .6s ease-in infinite;  
  -ms-animation: spin .6s ease-in infinite;
  -o-animation: spin .6s ease-in infinite;
  animation: spin .6s ease-in infinite;
}

// Spin animation
@keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@-webkit-keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@-moz-keyframes spin {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@keyframes fadeIn{
  0% {
    opacity:0
  }
  100% {
    opacity:1
  }
}

.text-red {
  color: red;
}