.societe-list {
    margin-top: 0;
    .societe-datatable {
        * {
            vertical-align: top;
        }
    }
}

@media screen and (max-width: 480px) {
    .societe-list {
        margin-top: 2.5rem;
    }
    .societe-datatable {
        * {
            vertical-align: middle;
        }
    }
}