.agence-item {
    padding: .5rem 1.5rem;
    border: 1px solid #0c2461;
    
    font-weight: 600;
    margin-bottom: .5rem;
    border-radius: 5px;

    display: flex;
    align-items: center;

    .agence-name {
        color: #0c2461;
        flex: auto;
    }

    .agence-action {
        color: red;
        font-size: 18px;

        &:hover {
            opacity: .75;
            cursor: pointer;
        }
    }

    &:hover {
        border: 1px solid #051849;

        .agence-name {
            color: #051849;
        }
    }
}