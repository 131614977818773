.performance-page {
    margin-top: 0;
    .performance-list {
        .performance-datatable {
            * {
                vertical-align: top;
            }
        }
    }
}

.previous-search {
    display: flex;
    align-items: center;
    justify-content: center;

    .search-date {
        .search-text {
            margin-right: 1rem;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
    }
    
    @media screen and (max-width: 480px) {
        display: block;
        .previous-button{
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }

    @media screen and (max-width: 405px) {
        .search-date{
            display: block;
        }
    }
}

@media screen and (max-width: 480px) {
    .performance-page {
        margin-top: 10rem;

        .performance-list {
            .performance-datatable {
                * {
                    vertical-align: middle;
                    white-space: nowrap;
                }
            }
        }
    }
}
