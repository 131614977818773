.h735-loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        margin-right: 4px;
        -webkit-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
        -moz-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;  
        -ms-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
        -o-animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
        animation: spin 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s infinite;
    }

    @keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
    }

    @-webkit-keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
    }

    @-moz-keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
    }
}