.agence-page {
    margin-top: 0;
    .agence-list {
        .agence-datatable {
            * {
                vertical-align: top;
            }
        }
    }
}
@media screen and (min-width: 405px) and (max-width: 680px){
    .agence-page {
        margin-top: 5rem;
    }
}

@media screen and (max-width: 405px) {
    .agence-page {
        margin-top: 11rem;

        .agence-list {
            .agence-datatable {
                * {
                    vertical-align: middle;
                    white-space: nowrap;
                }
            }
        }
    }
}
