.frais-typeform {
    margin-top: 0;

    .disable-prestataire {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .75);
        z-index: 50;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;

        overflow: hidden;

        &.show {
            transform: scale(1);
            transition: all ease-in 200ms;
            transform-origin: bottom;
        }

        &.hide {
            transform: scale(1, 0);
            transition: all ease-out 200ms;
            transform-origin: bottom;
        }

        .disable-message {
            background-color: rgba(255, 255, 255, 1);
            padding: 2rem;
            font-size: 1.2rem;
            text-align: center;
            box-shadow: 0px 0px 3px -1px rgba(0, 0, 0, .25);
        }
    }

    .prestataire-add {
        padding: 2rem;
        border: 3px dashed gainsboro;
        border-radius: 5px;
    }
}

@media screen and (max-width: 480px) {
    .frais-typeform {
        margin-top: 1rem;

        &.frais-update {
            margin-top: 4.5rem;
        }
    }
}
