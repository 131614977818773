.pagination {
    .pagination-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;

        li {
            &.active-page {
                a {
                    color: white;
                    background-color: #3699FF !important;
                    border-color: #3699FF !important;
                }
            }
        }

        a {
            width: 3rem;
            height: 3rem;
            display: flex;
            font-size: 16px;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 5px;
            margin-right: 1rem;
            cursor: pointer;

            &:hover {
                background-color: #3699FF !important;
                color: white !important;
            }
        }
    }
}