.loading-animation {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 20px;
    height: auto;
    background-color: white;
    padding: 1.75rem 2rem;
    z-index: 50;

    // &.full-height {
    //     height: 100%;
    //     background-color: rgba(255, 255, 255, .5);
    // }

    .loading-content {
        display: flex;

        i {
            margin-left: 1rem;
            font-size: 32px;
            color: black;
        }
    }
}