.societe-form {
    .societe-info {
        width: 50%;
        margin-top: 0;
    }

    .societe-agences {
        width: 50%;
    }
}

@media screen and (max-width: 480px) {
    .societe-form {
        .societe-info {
            width: 100%;
            margin-top: 1rem;
        }

        &.societe-update {
            .societe-info {
                margin-top: 3rem;
            }
        }
        
    
        .societe-agences {
            width: 100%;
        }
    }
}