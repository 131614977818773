.commission-list {
    .commission-datatable {

        * {
            vertical-align: top;
        }
        .bien-picture {
            width: 125px;
    
            img {
                width: 100%;
            }
        }
    }
}