.commission-grandtotal {
    padding: 1rem 2rem;
    background-color: rgb(244, 245, 199);
    margin-bottom: 2rem;
    border-radius: .5rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .value-display {
        font-size: 14px;
        font-weight: bolder;
        display: flex;
        border-bottom: 1px dashed grey;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .value-formula {
        font-family: 'Courier New', Courier, monospace;
    }
}

.commission-list {
    .commission-item {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px dashed #ccc;

        .commission-wrapper {
            display: flex;
            align-items: center;

            .commission-title {
                width: 40%;

                &.commission-avanced {
                    color: #5a5a5a;
                    font-style: italic;
                }
            }

            .commission-amount {
                display: flex;
                align-items: center;
                margin-bottom: .5rem;
    
                .commission-percentage {
                    display: flex;
                    align-items: center;
    
                    input {
                        width: 100px;
                        margin-right: .5rem;
                    }

                    .input-large {
                        width: 150px;
                    }
                }

                .commission-part {
                    padding: 0 .5rem;
                    white-space: nowrap;
                }
            }
        }
    }
}

.commission-subtitle {
    font-size: 16px;
    font-weight: 600;
}

.commission-marge {
    padding: 1rem 2rem;
    background-color: #dbf6de;
    margin-bottom: 1rem;
    border-radius: .5rem;

    .value-display {
        font-size: 14px;
        font-weight: bolder;
        display: flex;
        border-bottom: 1px dashed grey;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .value-formula {
        font-family: 'Courier New', Courier, monospace;
    }
}