.auth-page {
    display: flex;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;

    * {
        border-radius: 0 !important;
    }

    .auth-form {
        flex: auto;
        height: 100%;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;

        .logo-wrapper {
            padding: 1rem;
        }

        .form-group {
            margin-bottom: 1rem;

            .error-message {
                color: red;
                padding-top: .5rem;
            }
        }
    }

    .auth-background {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 72.5%;
        height: 100%;
    }
}

@media only screen and (max-width: 480px) {
    .auth-page {
        flex-direction: column-reverse;
        
        .auth-form {
            width: 100%;
        }

        .auth-background {
            width: 100%;
            background-size: cover;
        }
    }
}